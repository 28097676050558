<template>
  <div class="header-item">
      <div class="item-content">
        <label>所属销售：</label>  
           <el-select
           style=" width: 160px"
              v-model="Value"  
              @change="changeSale"
              filterable clearable @clear="clearSale"
              placeholder="请输入销售名称"
              :loading="loading">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      options:[],//销售列表select
      loading: false,
      // Value:this.selectValue,
      Value:this.selectValue,
    }
  },
  mounted() {
    this.getOwnerList()
  },

  props:['selectValue'],
  methods: {
    async getOwnerList() {
        let params = {
          ownerId :this.Value || ''
        }
         let res = await this.$ZGManager.businessOwner(params);
          this.options = res.data;
      },
      clearSale() {
        this.$emit('clearSale')
      },
      changeSale() {
        this.$emit('changeSale',this.Value)
      }
  },
}
</script>