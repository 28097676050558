<template>
  <div class="content">
    <!-- 搜索框 -->
    <div class="header-item">
      <div class="item-content">
        <label>项目名称：</label>
        <el-input style="display: inline-block; width: 177px"
          v-model="params.name" placeholder="请输入项目名称/编号" clearable @clear="getList(0)"></el-input>
      </div>
    </div>

    <sale-query @clearSale="getList(0)" @changeSale="changeSale($event)" :selectValue="this.params.ownerId"></sale-query>
    <!-- <div class="header-item">
      <div class="item-content">
        <label>所属销售：</label>  
           <el-select
           style=" width: 178px"
              v-model="params.ownerId"  
              filterable clearable @clear="getList(0)"
              placeholder="请输入销售名称"
              :loading="loading">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
      </div>
    </div> -->
    <!-- 搜索按钮 -->
    <div class="header-item">
      <el-button type="primary"  icon="el-icon-search" @click="getList(0)">搜索</el-button>
    </div>
      <div class="header-item">
        <el-button type="primary"  icon="el-icon-refresh-right" @click="getList(0)">刷新</el-button>
      </div>
    <div style="float:right;margin-right:10px" >
      <el-button type="primary"  icon="el-icon-plus" @click="goAddPage">新增商机</el-button>
    </div>
    <!-- 状态 -->
    <div>
      <div class="header-item">
        <span style="word-spacing: 24px;">状 态：</span>
        <el-select v-model="params.status" @change="getList(0)" style=" width: 177px" placeholder="全部" >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> 
      <div class="header-item">
        <span >发布时间：</span>
        <el-date-picker
          v-model="times"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="setData"
          value-format='yyyy-MM-dd HH:mm:ss '
          :clearable="false"
         >
        </el-date-picker>

        <!-- <el-date-picker  style="display: inline-block; width: 178px"
          placeholder="请选择日期" type="date" @input="getList(0)"
          suffix-icon="el-icon-date" value-format='yyyy-MM-dd'
          @change="setData"
          v-model="params.startTime">
        </el-date-picker> -->
      </div> 
      
    </div>
        <!--table公司名片表格  -->
    <el-table  tooltip-effect="dark" text-align='center' :data="dataList"  align="center"
    class="tableBox" style="width: 100%; margin-top: 10px"
    header-row-class-name="table-header-style"
    @sort-change="changeTableSort"
    :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
      <el-table-column label="项目编号"   width="109" >
        <template slot-scope="scope">
          {{scope.row.id || '无'}}
        </template>
      </el-table-column>
      <el-table-column label="项目名称" >
        <template slot-scope="scope">
          {{scope.row.projectName || '无'}}
        </template>
      </el-table-column>
      <el-table-column label="查看人数"  align="center"  width="110" prop="viewNumber" :sortable="'custom'" >
        <template slot-scope="scope">
          {{scope.row.viewNumber || 0}}
        </template>
      </el-table-column>
      <el-table-column label="咨询人数"  width="110" align="center"  prop="askNumber"  :sortable="'custom'">
          <template slot-scope="scope">
          {{scope.row.askNumber || 0}}
        </template>
      </el-table-column>
      <el-table-column label="发布人" width="140">
        <template slot-scope="scope">
          {{scope.row.publisher || '无'}}
        </template>
      </el-table-column>
      <el-table-column label="所属销售" width="140">
        <template slot-scope="scope">
          {{scope.row.salename || '无'}}
        </template>
      </el-table-column>
      <el-table-column label="发布时间" width="220">
        <template slot-scope="scope">
          <span>{{scope.row.createAt | dateFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态"  width="100">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
            @change="updateStatus(scope.row)"
            active-color="#409EFF"
            inactive-color="#999999">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="160" >
        <template slot-scope="scope">
          <el-button type="text" style="color: #ff0000" @click="updateStatus(scope.row,-1)">删除</el-button>
          <el-button  type="text" @click="goEditPage(scope.row)">编辑</el-button>
          <el-button  type="text" @click="editSale(scope.row)">更换销售</el-button>
          <!-- 编辑销售 -->
          <el-dialog title="更换销售" :visible.sync="dialogSalenameVisible" width="375px">

              <el-select
           style=" width: 300px"
              v-model="editSalename"  
              filterable 
              placeholder="请选择销售"
              :loading="loading">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>

<!-- 
              <el-select v-model="editSalename" placeholder="请选择销售"  style=" width: 300px">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select> -->
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogSalenameVisible = false">取 消</el-button>
              <el-button type="primary" @click="editSaleSure">确 定</el-button>
            </div>
          </el-dialog>
        </template>
      </el-table-column>
    </el-table>
  <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        :background="true"
        @current-change="getList"
        :current-page.sync="params.pageIndex"
        :page-size="params.pageNumber"
        layout="total, prev, pager, next, jumper"
        :total='plazaCardTotal'>
      </el-pagination>
    </div>
  </div>
</template>
<script>
import SaleQuery from '@/websites/cms/components/SaleQuery'
export default {
  data() {
    return {
      dataList:[],
      plazaCardTotal:0,
      params:{
        name:'',
        orderParam:'',
        sort:'',
        flag:1,
        pageNumber:10,
        pageIndex:0,
        status:'',
        startTime:'',
        endTime:'',
        ownerId:'',
      },
      times:[],
      statusOptions:[{
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '已上架'
        }, {
          value: '0',
          label: '已下架'
      }],
      ownername:'',//销售名字
      options: [],//销售列表select
      loading: false,
      dialogSalenameVisible:false,
      editSalename:'',
      editId:'',
      } 
  },
  created() {
    this.getList()
    this.getOwnerList()
  },
  methods: {
      async getOwnerList() {
        let params = {
          ownerId : this.params.ownerId || ''
        }
         let res = await this.$ZGManager.businessOwner(params);
          this.options = res.data;
      },
    //修改data输入框时间格式
    setData(data) {
      console.log(data);
      this.params.startTime= data[0];
      this.params.endTime= data[1];
      this.getList(0)
    },
    //分割字符串返回数组
    backArray(str) {
      return str.split(',')
    },
    changeSale(data) {
      // console.log('data=========',data);
      this.params.ownerId = data;
    },
    //获取商机中心列表
   async getList(page) {
       for(var key in this.params) {
        if(this.params[key] == '') {
          delete this.params[key]
        }
      }
      this.params.pageIndex=page;
      if(this.params.startTime!= undefined){
        this.params.startTime = this.params.startTime
      }
      if(this.params.endTime!= undefined){
        this.params.endTime = this.params.endTime
      }
      let res = await this.$ZGManager.businessList(this.params)
      if (res.status !== 200)return;
      this.dataList = res.data.list;
      this.plazaCardTotal = res.data.total_rows;
    },
    //升序降序
    changeTableSort(column, prop, order) {
      this.params.orderParam = column.prop;
      if (column.order == 'ascending') {
        this.params.sort  = 'asc';
      }else if(column.order == 'descending') {
        this.params.sort  = 'desc'
      }
      this.getList();
    },
    //修改商机状态
    async updateStatus(row,type) {
      let status = '';
      status = row.status;
      console.log(row.status);
      try {
        if (type == -1) {
        status = -1
        let dialog = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        if(dialog != "confirm") return;
      }
      let res = await this.$ZGManager.businessUpdate(row.id,{status:status})
      if(res.status != 200) return;
      console.log(res);
      this.getList(0);
      } catch (error) {
        console.log(error);
      }   
    },
    //editSale更换销售
    editSale(row) {
      this.editSalename=row.owner;
      this.editId = row.id;
      this.dialogSalenameVisible = true
    },
    async editSaleSure() {
      let params = { 
        owner:this.editSalename
      }
      let res = await this.$ZGManager.businessUpdate(this.editId,params);
      if (res.status !==200) return;
      this.$message.success('修改成功')
      console.log(res);
      this.getList();
      this.dialogSalenameVisible = false
    },
    //点击跳转增加商机page
    goAddPage() {
      let jumpPage = this.$router.resolve({
        path:'/opportunity/add'
      });
      window.open(jumpPage.href,'_blank')
    },
    goEditPage(row) {
       let jumpPage = this.$router.resolve({
        path:'/opportunity/edit',
        query: {
            id:row.id,
        }
      });
      window.open(jumpPage.href,'_blank')
    }

  },
  components: {
    'sale-query':SaleQuery
  }
}
</script>
<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 50px;
  font-size: 14px;
   .pagination {
    float: right;
    margin-top: 20px;
  }
  .breadcrumb {
    padding-bottom: 20px;
    span {
      &:nth-child(1) {
        cursor: pointer;
      }
      &:nth-child(1):hover {
       color: #409EFF;
      }
    }
  }
  ::v-deep .el-dialog{
    height: 80%;
    overflow: auto;
  }
  .viewDetails {
    p {
      font-size: 14px;
      line-height: 20px;
      color: #999999;
      padding-bottom: 19px;
    }
    .hr {
      width: 100%;
      height: 5px;
      background-color: #FAFAFA;
    }
    .project-item {
      margin-top: 15px;
      margin-bottom: 30px;
      img {
        display: inline-block;
        margin:10px  15px 0 0;
        height: 82px;
        width: 82px;
        border-radius: 5px;
      }
    }
    .title {
      color: #000000;
      font-weight: 700;
      padding-bottom: 10px;
    }
    .des {
      font-size: 12px;
      line-height: 20px;
    }
  
  }
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}
.roles-tag{
  margin-right: 5px;
  margin-bottom: 5px;
}
::v-deep .el-dialog{
     height: 230px!important;
     overflow: auto;
    }

</style>